<template>
  <section>
    <div class="content-header">
      <h2>Power, privilege and oppression: Pulling it all together</h2>
    </div>
    <div class="content-wrapper">
      <p>Check out this short CBC video, <a href="https://www.youtube.com/watch?v=1Ea5WudpEAs" target="_blank">“Privilege is power. How you can use it to do some good!”</a>.</p>
      <p>In just five minutes, Kelly Kitagawa explains how to recognize your own privilege—and learn how to use it (and remain humble!).</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
